import { Controller } from "stimulus"
import debounce from "lodash/debounce"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "errorMessage", "form" ]
  static values = {
      isConnected: Boolean
  }

  initialize() {
      this.submit = debounce(this.submit, 500, { leading: false, trailing: true })

      this.errorMessageTarget.style.visibility =
          this.isCredentialsPresent() && !this.isConnectedValue ? "visible" : "hidden"
  }

  // Displays the error message
  errorMessage() {
      this.errorMessageTarget.style.visibility = "visible"
  }

  // Fires the form
  submit() {
      this.errorMessageTarget.style.visibility = "hidden"
      Rails.fire(this.formTarget, "submit")
  }

  // PRIVATE

  /**
   * Returns true if both user id and password fields have non-whitespace text in them.
   *
   * @returns {boolean}
   */
  isCredentialsPresent() {
      return ['user-id', 'password']
          .map(id => this.formInputValue(id).trim())
          .every(credential => credential !== '')
  }

  /**
   * Return the value of the given input element on the form.
   *
   * @param id {string} id of the input element
   * @returns {string}
   */
  formInputValue(id) {
      return this.formTarget.elements.namedItem(id).value
  }
}
